module.exports = [{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ae36eedd65864f60919d20d4d4f2e732@sentry.io/5196369","sampleRate":1,"environment":"gatsby-production","enabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"4031532897","enableOnDevMode":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"rum":{"applicationId":"977855e2-52f9-4866-a1ea-3aa206c26f03","clientToken":"puba8430946c2227304ea841bf68f52a369","service":"mktg-toasttab-com-headless","env":"staging","site":"datadoghq.com","sessionSampleRate":1,"trackResources":true,"trackUserInteractions":true,"trackLongTasks":true,"enabled":true}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
